import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import { SiGmail } from 'react-icons/si'
import { BsFacebook } from 'react-icons/bs'
import { BsInstagram} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
      <div className='header_socials'>
          <a href="http://linkedin.com" target="_blank"><BsLinkedin/></a>
          <a href="http://github.com" target="_blank"><FaGithub /></a>
          <a href="http://gmail.com" target="_blank"><SiGmail /></a>
          <a href="http://facebook.com" target="_blank"><BsFacebook /></a>
          <a href="http://instagram.com" target="_blank"><BsInstagram/></a>
    </div>

  )
}

export default HeaderSocials