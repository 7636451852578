import React from 'react'
import './Services.css'
import {BiCheck} from 'react-icons/bi'

const Services =() =>{
  return (
    <section id="services">
      <h5>What i Offer</h5>
      <h2>Services</h2>
      <div className="container services_container">
        {/*=========UI/UX===========*/}
        <article className="service">
          <div className="service_head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
          </ul>
        </article>
        {/*=========End of UI/UX===========*/}
        {/*=========Web Developement===========*/}
        <article className="service">
          <div className="service_head">
            <h3>Web Developement</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
          </ul>
        </article>
        {/*=========End of Web Developement===========*/}

        {/*=========Content Creation===========*/}
        <article className="service">
          <div className="service_head">
            <h3>Content Creation</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
            </li>
          </ul>
        </article>
        {/*=========End of Content Creation===========*/}
      </div>
    </section>
  );
}

export default Services